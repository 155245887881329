import React from 'react';
import { useLocation } from 'react-router-dom';
import { Worker, Viewer, ProgressBar, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Container } from 'react-bootstrap';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function PDFRender() {
  let location = useLocation();
  const filePath = location.pathname;

  const BASE_URL = window.location.origin;
  const FILE_URL = `${BASE_URL}${filePath}.pdf`.replace('pdf/', '');

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
      <Container style={{ height: '100vh', padding: 0, 'max-width': '100%' }}>
        {window.showAd && <div style={{ position: 'fixed', bottom:85, width:210, height:65, zIndex:9999 }}>
          <img height={75} src="https://tiiny.host/ad.png" alt="tiiny.host" />
        </div>}
        <Viewer
          renderLoader={(percentages) => (
            <div style={{ width: '240px' }}>
              <ProgressBar progress={Math.round(percentages)} />
            </div>
          )}
          fileUrl={FILE_URL}
          plugins={[defaultLayoutPluginInstance]}
          defaultScale={SpecialZoomLevel.PageWidth}
        />
      </Container>
    </Worker>
  );
}
