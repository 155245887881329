import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import PDFRender from './components/pdf-renderer/pdf-renderer';
import NotFound from "./components/not-found/not-found";
import FilesView from "./components/files-view/files-view";

import './index.css';

export const BASE_DEPLOY_PATH = 'https://tiiny.host/c-ui'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<FilesView />} />
      <Route path="/pdf/*" element={<PDFRender />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
