import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const CustomTooltip = ({children, label}) => {
  const renderTooltip =
    <Tooltip id="button-tooltip">
      {label}
    </Tooltip>;

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{show: 50, hide: 100}}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
