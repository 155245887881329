import React from 'react';
import {Button} from "react-bootstrap";

const NotFound = () =>
  <div style={{ maxWidth: 800, margin: 'auto', textAlign: 'center', marginTop: 200 }}>
    <h1 className="bold mt-5">Document not found</h1>
    <p className="mt-3">
      Sorry, that document doesn't exist
    </p>
    <Button
      className="mt-3 mb-130"
      href="/"
    >
      Go home
    </Button>
  </div>;

export default NotFound;